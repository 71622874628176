<template>
  <v-tabs color="transparent">
    <v-tab>
      {{ $tc('_import', 2) }}
    </v-tab>
    <v-tab-item class="pt-1">
      <v-card class="ma-1">
        <v-layout>
          <v-flex xs12>
            <import />
          </v-flex>
        </v-layout>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import Import from '../components/tools/Import';
export default {
  props: [],
  data() {
    return {
      org: undefined,
      isLoading: 0,
    };
  },
  components: {
    Import,
  },
};
</script>
